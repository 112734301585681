import React from 'react'
import styled from 'styled-components'

// Components
import Layout from 'components/layout/Layout'
import ParseContent from 'components/shared/ParseContent'

const Container = styled.section`
  @media (min-width: 992px) {
    padding-top: 100px;
  }

  @media (max-width: 991px) {
    padding-top: 75px;
  }
`

const Block = styled(ParseContent)`
  background-color: ${({ theme }) => theme.color.face.light};
  border-radius: 25px;
  box-shadow: 0 0 25px rgb(0 0 0 / 10%);

  & a {
    text-decoration: underline;
  }

  .button a {
    text-decoration: none;
  }
`

interface NotFoundPageProps {
  location: any
}

const NotFoundPage: React.FC<NotFoundPageProps> = ({ location }) => (
  <Layout transparentHeader={false} location={location}>
    <Container className="container">
      <div className="py-5">
        <div className="row justify-content-center">
          <div className="col-lg-8 py-lg-5">
            <Block
              className="py-5 px-lg-5 px-4"
              content={`<h1 class="compact-title">Oh-oh!<br /><strong>404!</strong></h1>De link waar je op klikte leidt naar een oude of niet bestaande pagina. Heb je een vraag of opmerking? Neem dan vrijblijvend telefonisch contact met ons op via <a href="tel:033 - 454 9000">033 - 454 9000</a> of per mail via <a href="mailto:info@addcomm.nl">info@addcomm.nl</a>.<br /><br /><a href="/" class="orange-big-button">Terug naar website</a>`}
            />
          </div>
        </div>
      </div>
    </Container>
  </Layout>
)

export default NotFoundPage
